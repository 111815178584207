import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { TAGS } from "../constants/tags"
import LANG_CONFIG from "../data/lang"

// This is shared in perigon-plugin/gatsby-node.js
function getDate() {
    // Create a date object from a date string
    const date = new Date();

    // Get year, month, and day part from the date
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate
}

const TagPill = ({ tag, lang }) => {
    const { text, backgroundColor, fontColor } = TAGS[tag]

    return <span
        className="pill"
        style={{
            backgroundColor,
            color: fontColor
        }}
    >{text[lang]}</span>
}

const List = ({ edges, limit }) => {
    const posts = limit >= 0 ? edges.slice(0, limit) : edges
    return <ol className="blog-list">
        {posts.map(edge => {
            const title = edge.node.frontmatter.title || edge.node.fields.slug

            return (
                <li key={edge.node.fields.slug} className="blog-list-item">
                    <div>
                        <TagPill tag={edge.node.frontmatter.tag} lang={edge.node.frontmatter.lang} />
                        <Link to={edge.node.fields.slug} itemProp="url" className="title">
                            <span itemProp="headline">{title}</span>
                        </Link>
                        <span><i className="time-to-read desktop"> - {edge.node.timeToRead} min read</i></span>
                    </div>
                    <div className="mobile time-to-read">
                        <span className="date">{edge.node.frontmatter.date}&nbsp;</span>
                        <span><i>- {edge.node.timeToRead} min read</i></span>
                    </div>
                    <strong className="date desktop">{edge.node.frontmatter.date}&nbsp;</strong>
                </li>
            )
        })}
    </ol>
}

const Section = ({ tag, edges, limit, lang, subtag }) => {
    const { text, url, description, subtags, parentCategory } = TAGS[tag]
    const config = LANG_CONFIG[lang] || LANG_CONFIG["en"]
    const posts = edges.filter(e => e.node.frontmatter.tag === tag || e.node.frontmatter.subcategories?.includes(tag))
    return (
        <>
            {subtag && (
                <h4>
                    {text[lang]}
                </h4>
            ) || (
                    <h3>
                        {text[lang]}
                    </h3>
                )}
            <p>
                {description[lang]}
            </p>
            <List edges={posts} limit={limit}> </List>
            <Link to={`${config.layout.links.root.href}posts/categories/${url}/`}>View All {text[lang]} Posts</Link>
            {subtags?.map(s => {
                return (
                    <div>
                        <Link to={`${config.layout.links.root.href}posts/categories/${TAGS[s].url}/`}>View {TAGS[s].text[lang]} Posts</Link>
                    </div>
                )
            })}
        </>
    )
}

const BlogIndex = ({ data, location, pageContext }) => {
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const avatar = data?.avatar?.childImageSharp?.fixed
    const { edges } = data.allMarkdownRemark
    const {
        lang,
    } = pageContext

    const config = LANG_CONFIG[lang] || LANG_CONFIG["en"]

    return (
        <Layout
            lang={lang}
            location={location}
            title={siteTitle}
            description={siteDescription}
            avatar={avatar}
        >
            <SEO title={siteTitle} />
            <h2>Recent Posts</h2>
            <List edges={edges} limit={10} />
            <Link to={`${config.layout.links.root.href}posts/`}>View All Posts</Link>
            <h2>Categories</h2>
            <Section tag={3} edges={edges} limit={5} lang={lang} />
            <Section tag={4} edges={edges} limit={5} lang={lang} />
            <Section tag={2} edges={edges} limit={5} lang={lang} />
        </Layout>
    )
}

export default BlogIndex

export const query = graphql`
  query IndexTemplate($lang: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "post" }, lang: { eq: $lang } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            description
            lang
            subcategories
            tag
          }
        }
      }
    }
  }
`
