// @flow strict
const SUBCATEGORIES = {
    1: {
        text: "Numerical Methods",
    },
    2: {
        text: "Optimization",
    },
    3: {
        text: "Physics",
    },
    5: {
        text: "The Graphics Pipeline",
        image: "https://res.cloudinary.com/dx1kpewvo/image/upload/v1668358091/home/g102402_ib3cfm.png",
    },
    
    7: {
        text: "Simulation",
    },
    8: {
        text: "JavaScript"
    },
    9: {
        text: "Hash Tables"
    },
    10: {
        text: "Data Structures"
    },
    11: {
        text: "C"
    },
}
const TAGS = {
    2: {
        id: 2,
        text: {
            en: "Math & Science",
            fr: "Math & Science"
        },
        description: {
            en: "This is a collection of my blog posts that explain math and science principles.",
            fr: "La collection de mes articles de blog qui expliquent les principes des mathématiques et des sciences."
        },
        backgroundColor: "#3A5C5D",
        fontColor: "white",
        parentCategory: 2,
        url: "math-and-science"
    },
    3: {
        id: 3,
        text: {
            en: "Graphics",
            fr: "Infographie",
        },
        description: {
            en: "My blog posts that discuss in great detail WebGPU, the Graphics Pipeline, and Extended Position Based Dynamics (XPBD).",
            fr: "Mes articles de blog qui traitent en détail de WebGPU, du pipeline graphique et de la dynamique basée sur la position étendue (XPBD).",
        },
        backgroundColor: "rgb(77 120 181)",
        fontColor: "white",
        url: "graphics",
        subtags: [7, 6, 8, 9]
    },
    4: {
        id: 4,
        text: {
            en: "Coding",
            fr: "Coding"
        },
        description: {
            en: "My blog posts that explain software concepts to help me with my graphics work... this includes deep dives into Data Structures.",
            fr: "Mes articles de blog qui expliquent les concepts logiciels pour m'aider avec mon travail graphique... cela inclut des plongées approfondies dans les structures de données."
        },
        backgroundColor: "#bd0000",
        fontColor: "white",
        parentCategory: 4,
        subtags: [],
        url: "coding"
    },
    5: {
        id: 5,
        text: {
            en: "Meta",
            fr: "Meta"
        },
        description: {
            en: "My blog posts that talk about my blog. These posts highlight special announcements and big changes I've added to the blog.",
            fr: "Mes articles de blog qui parlent de mon blog. Ces messages sont destinés aux annonces spéciales et aux grands changements que j'ai ajoutés au blog." },
        backgroundColor: "#9F2B68",
        fontColor: "white",
        parentCategory: 5,
        url: "meta"
    },
    6: {
        id: 6,
        text: {
            en: "Extended Position Based Dynamics - XPBD",
            fr: "Extended Position Based Dynamics - XPBD"
        },
        description: {
            en: "This is a collection of my blog posts that explain Extended Position Based Dynamics - XPBD.",
            fr: "La collection de mes articles de blog qui expliquent Extended Position Based Dynamics - XPBD."
        },
        backgroundColor: "#3A5C5D",
        fontColor: "white",
        parentCategory: 3,
        url: "xpbd"
    },
    7: {
        id: 7,
        text: {
            en: "WebGPU",
            fr: "WebGPU"
        },
        description: {
            en: "This is a collection of my blog posts that go into WebGPU.",
            fr: "La collection de mes articles de blog qui expliquent WebGPU."
        },
        backgroundColor: "#3A5C5D",
        fontColor: "white",
        parentCategory: 3,
        url: "webgpu"
    },
    8: {
        id: 8,
        text: {
            en: "Cloth Simulations",
            fr: "Simulations de tissu"
        },
        description: {
            en: "My blog posts where we create a cloth simulation in WebGPU.",
            fr: "Mes articles de blog où on crée une simulation de tissu avec WebGPU." },
        backgroundColor: "#9F2B68",
        fontColor: "white",
        parentCategory: 3,
        url: "cloth-simulation"
    },
    9: {
        id: 9,
        text: {
            en: "Rendering Pipeline",
            fr: "Pipeline de Rendu"
        },
        description: {
            en: "My blog posts where we explore the rendering pipeline in WebGPU.",
            fr: "Mes articles de blog où l'on découvre le pipeline de rendu avec WebGPU." },
        backgroundColor: "#9F2B68",
        fontColor: "white",
        parentCategory: 3,
        url: "rendering-pipeline"
    },
}

module.exports = {
    TAGS: TAGS,
    SUBCATEGORIES: SUBCATEGORIES
}